<template>
  <component :is="retrocessionData === undefined ? 'div' : 'b-card'">
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="retrocessionData === undefined">
      <h4 class="alert-heading">Error fetching retrocession data</h4>
      <div class="alert-body">
        No retrocession found with this retrocession id. Check
        <b-link class="alert-link" :to="{ name: 'retrocession-list' }">
          Retrocession List
        </b-link>
        for other retrocession.
      </div>
    </b-alert>
    <retrocession-edit-tab-information :retrocession-data="retrocessionData" :user-options="userOptions"
      :bank-options="bankOptions" :client-bank-options="clientBankOptions" :currency-options="currencyOptions"
      :filter-table-options="filterTableOptions" :jvsakk_ac="jvsakk_ac" @refetch-data="refetchData" class="m-sm-2 mr-1" />
  </component>
</template>
<script>
import { BTab, BTabs, BCard, BAlert, BLink } from "bootstrap-vue";
import { ref, onUnmounted, onMounted } from "@vue/composition-api";
import router from "@/router";
import store from "@/store";
import retrocessionStoreModule from "../retrocessionStoreModule";
import retrocessionEditTabInformation from "./retrocessionEditTabInformation.vue";

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    retrocessionEditTabInformation,
  },
  setup() {
    const retrocessionData = ref({});

    const RETROCESSION_STORE_MODULE_NAME = "app-retrocession";

    const retrocession = {
      id: 0,
      year: "",
      quarter: "",
      bank: "",
      jvsakk_ac: "",
      account_no: "",
      client_name: "",
      client_c_name: "",
      manager: "",
      amounts: [
        {
          currency: "",
          number: "",
        },
      ],
      report_name: "",
      report_date: "",
      remarks: "",
    };

    if (!store.hasModule(RETROCESSION_STORE_MODULE_NAME))
      store.registerModule(
        RETROCESSION_STORE_MODULE_NAME,
        retrocessionStoreModule
      );

    onUnmounted(() => {
      if (store.hasModule(RETROCESSION_STORE_MODULE_NAME))
        store.unregisterModule(RETROCESSION_STORE_MODULE_NAME);
    });

    onMounted(() => {
      refetchData();
      refetchOption();
      refetchClientBank();
    });

    const refetchData = () => {
      if (router.currentRoute.name == "retrocession-create") {
        retrocessionData.value = retrocession;
      } else {
        store
          .dispatch(RETROCESSION_STORE_MODULE_NAME + "/fetchRetrocession", {
            id: router.currentRoute.params.id,
          })
          .then((response) => {
            console.log(response.data.retrocession[0].id)
            retrocessionData.value = response.data.retrocession[0];
          })
          .catch((error) => {
            if (error.response.status === 404) {
              retrocessionData.value = undefined;
            }
          });
      }
    };

    const userOptions = ref([]);
    const bankOptions = ref([]);
    const clientBankOptions = ref([]);
    const currencyOptions = ref([]);
    const filterTableOptions = ref([]);
    const jvsakk_ac = ref({
      search: "",
      selected: null,
      select: null,
      options: [],
      fileds: [
        "JVSakk A/C No.",
        "client Name",
        "Chinese Name",
        "Relationship Manager",
        "Custodian Bank",
        "Account No.",
        "AccountStatus",
      ],
    });

    const refetchOption = () => {
      store
        .dispatch("app/fetchOptionList", {
          user: true,
          bank: true,
          currency: true,
        })
        .then((response) => {
          userOptions.value = response.data.users;
          bankOptions.value = response.data.banks;
          currencyOptions.value = response.data.currencies;
        });
    };

    const refetchClientBank = () => {
      store
        .dispatch(RETROCESSION_STORE_MODULE_NAME + "/fetchClientBanks")
        .then((response) => {
          clientBankOptions.value = response.data.client_banks;
          console.log(clientBankOptions);
          filterTableOptions.value = response.data.client_banks.map(
            (client_bank) => {
              return {
                jvsakk_ac: client_bank.client_id.toString(),
                client_name: client_bank.client && client_bank.client.name_en,
                client_c_name: client_bank.client && client_bank.client.name_cn,
                manager: (client_bank.client && client_bank.client.relationship_manager && client_bank.client.relationship_manager.name),
                bank: client_bank.bank && client_bank.bank.name,
                account_no: client_bank.account_number,
                AccountStatus: client_bank.bank && client_bank.bank.date_of_termination
                  ? "Inactive"
                  : "Active",
              };
            }
          );


          jvsakk_ac.value.options = filterTableOptions.value

        })
        .catch((error) => {
          console.log("Fail to refetch client bank data.");
        });
    };

    return {
      refetchData,
      refetchOption,
      retrocessionData,
      userOptions,
      bankOptions,
      clientBankOptions,
      currencyOptions,
      filterTableOptions,
      jvsakk_ac
    };
  },
};
</script>